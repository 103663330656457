
import React, {useState, useEffect, useRef, useContext} from 'react'
import axios from '../../../utilis/axios'
import {Link} from 'react-router-dom'
import Swiper, {Navigation, Pagination, Autoplay, Scrollbar, Parallax, EffectFade, Controller} from 'swiper'
import content from '../../../content/landingPage.json'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { getFixedPrice, increasePriceByPercentage } from '../../../utilis/helpers'
import { Store } from "../../../store";
import { PriceHelper, Regions } from '../../../helper/PriceHelper'


Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, Parallax, EffectFade, Controller]);

const Instructors = () => {

    const [instructors, setInstructors] = useState([]);

    const swiper = useRef(null)
    const swiperMe = useRef(null)

    useEffect(() => {
        swiperMe.current = new Swiper(swiper.current,{
            slidesPerView: 3,
            spaceBetween: 40,
            speed: 600,
            loop: false,
            breakpoints: {
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20
                },
                480: {
                  slidesPerView: 2,
                  spaceBetween: 30
                },
                640: {
                  slidesPerView: 3,
                  spaceBetween: 40
                }
              }
        })
    }, [instructors])


    useEffect(() => {
        fetchCourses();
        // eslint-disable-next-line
    }, [])


    const fetchCourses = async () => {
        try{
            const res = await axios.get('/api/student/instructors/star');
            formatData(res.data);
        } catch(err){
            console.log(err);
        }
    }

    const formatData = (data) => {
        const allInstructors = []
        for(let i=0; i < data.length; i++){
            const index = allInstructors.findIndex(item => item._id === data[i].instructor._id)
            if(index > -1){
                const maxDiscount = Math.max(...data[i].discount.map(item => item.discount));
                const min_price_per_class = data[i].price_per_class * (1-(maxDiscount/100))
                
                if(!allInstructors[index].minPrice || allInstructors[index].minPrice > min_price_per_class){
                    allInstructors[index].minPrice = min_price_per_class
                }

                allInstructors[index].instruments.push({
                    lesson_id: data[i]._id,
                    instrument: data[i].instrument,
                    level: data[i].level,
                    price_per_class: data[i].price_per_class,
                    video: (data[i].videos && data[i].videos.length > 0) ? data[i].videos[0].url : "",
                    min_price_per_class: min_price_per_class
                })
            } else{
                const maxDiscount = Math.max(...data[i].discount.map(item => item.discount));
                const min_price_per_class = data[i].price_per_class * (1-(maxDiscount/100))
                allInstructors.push({
                    _id: data[i].instructor._id, 
                    profile: data[i].instructor_profile, 
                    instructor: data[i].instructor,
                    // minPrice: min_price_per_class,
                    minPrice: increasePriceByPercentage(min_price_per_class),
                    instruments: [{
                        lesson_id: data[i]._id,
                        instrument: data[i].instrument.toLowerCase(),
                        level: data[i].level,
                        price_per_class: data[i].price_per_class,
                        min_price_per_class: min_price_per_class,
                        video: (data[i].videos && data[i].videos.length > 0) ? data[i].videos[0].url : ""
                    }],
                    data: data[i],
                })
            }
        }
        setInstructors(allInstructors)
    }



    return (
        <div class="mt-lg-6 py-6 px-lg-5 px-2" style = {{backgroundColor: '#c8c8c8'}}>
            <div class="container">
                <div class="row justify-content-center ">
                    <div class="col-12 col-md-10 col-lg-12 text-center" >
                        <p class="mb-2 " >{content.home.instructors.title}</p>
                        <h3 class="display-4 mb-2 px-lg-5 px-0"  > {content.home.instructors.description}</h3>
                        <div class = 'mt-3'>
                            <Link to = "/instructors" class = 'btn  btn-primary text-dark pointer mb-5'>{content.home.instructors.cta}</Link>
                            <AnchorLink href = "#free-session" class = 'btn  btn-white text-dark pointer ml-3 mb-5'>Book a free session</AnchorLink>
                        </div>
                        <div class="swiper swiper-button-style-3 mt-5 " >
                            <div class="swiper-container" ref = {swiper}>
                                <div class="swiper-wrapper">
                                    {instructors.map(item => 
                                    <div className = 'swiper-slide'>
                                        <TeacherCard data = {item}/>
                                    </div>)}
                                </div>
                            </div>
                        </div>
                        <div class = "row">
                             <div onClick = {() => swiperMe.current.slideNext()} class="swiper-button-next bg-white p-3">
                                </div>
                            <div onClick = {() => swiperMe.current.slidePrev()}  class="swiper-button-prev bg-white p-3">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Instructors




const TeacherCard = ({data}) => {
    const { state} = useContext(Store);
    return (
        <div className = 'card '>
            <img src={data.instructor.avatar} alt="..." class="card-img" style = {{height: '260px', objectFit: 'cover', borderRadius: '0px'}}/>
            <div className = 'card-body text-left'>
                <h4 class = 'h2 mb-2'>{data.instructor.firstName + ' ' + data.instructor.lastName}</h4>
                <div class = 'mb-2'>
                    {data.instruments.map(item => <span class ='badge badge-primary mr-2'>{item.instrument[0].toUpperCase() + item.instrument.slice(1)}</span>)}
                </div>
                <p class = "small mb-0">{data?.profile?.bio?.length > 80 ? data?.profile?.bio?.slice(0, 80) + '...' : data?.profile?.bio}</p>
            </div>
            <div class = 'card-footer py-3'>
                <div class = 'row align-items-center text-left'>
                    <div class = 'col'>
                        {/* <div class = 'small text-primary font-weight-bold '>Price starts from Rs {Math.ceil(data.minPrice + (0.18 * data.minPrice))}</div> */}
                        <div class='small text-primary font-weight-bold '>Price starts from {getFixedPrice(Math.ceil((data.minPrice + (0.18 * data.minPrice))), state.country.country)}</div>
                        {/* <div class = 'small text-primary font-weight-bold '>Price starts from Rs {PriceHelper.regionWiseCurencyConvert(Math.ceil((data.minPrice + (0.18 * data.minPrice))), Regions.INDIA)}</div> */}
                    </div>
                    <div class = 'col-auto text-right'>
                        <Link to = {`/lesson/${data.instruments[0].lesson_id}`} class = 'btn btn-primary mt-2'>Learn more</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}


