import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Login from '../pages/Auth/Login'
import Signup from '../pages/Auth/Register/Student'
import InstructorSignup from '../pages/Auth/Register/Teacher'
import ForgetPassword from '../pages/Auth/ForgetPassword'
import ResetPassword from '../pages/Auth/ForgetPassword/ResetPassword'

import AdminRoutes from './nestedRoutes/AdminRoutes'
import InstructorRoutes from './nestedRoutes/InstructorRoutes'
import StudentRoutes from './nestedRoutes/StudentRoutes'
import LandingPageRoutes from './nestedRoutes/LandingPageRoutes'
import ScrollToTop from '../HOC/ScrollToTop'
import ConfirmAccount from "../pages/Auth/ConfirmAccount";
import { PusherProvider } from "@harelpls/use-pusher";

import ReactGA from 'react-ga';
import { useEffect } from "react";
import axios from "axios";
import { Store } from "../store";
import { useContext } from "react";
ReactGA.initialize('G-JEY0F8ZR0V');
ReactGA.pageview(window.location.pathname + window.location.search);


const config = {
    clientKey: process.env.REACT_APP_PUSHER_KEY || "f0be17d8368760e0e0d9",
    cluster: process.env.REACT_APP_PUSHER_KEY_CLUSTER || "ap2",
};

console.log(config)


const AppRouter = () => {

    const { state, dispatch } = useContext(Store);
    // const country = state.country.country;
    async function getCountry() {
        try {
            const res = await axios.get('/api/country');
            if (res.status === 200) {
                let c = res.data?.country;
                if (c == 'IN') {
                    dispatch({ type: 'SET_COUNTRY', payload: c });
                } else if (c == 'AE') {
                    dispatch({ type: 'SET_COUNTRY', payload: c });
                } else if (c !== "Unknown") {
                    dispatch({ type: 'SET_COUNTRY', payload: "REST" });
                }
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getCountry()
    }, []);

    return (
        <>
        <Router>
            <PusherProvider {...config}>
            <ScrollToTop>
            <Switch>
                <Route exact path="/login" component={Login}  />
                <Route exact path="/signup" component={Signup}  />
                <Route path="/signup/instructor" component={InstructorSignup}  />
                <Route exact path="/reset-password/:id/:hash" component={ResetPassword}  />
                <Route exact path="/forgot-password" component={ForgetPassword}  />
                <Route exact path="/confirm-account/:id" component={ConfirmAccount}  />
                <Route path = "/admin"><AdminRoutes /></Route>
                <Route path = "/instructor"><InstructorRoutes /></Route>
                <Route path = "/my-dashboard"><StudentRoutes /></Route>
                <Route path = "/"><LandingPageRoutes /></Route>
            </Switch>
            </ScrollToTop>
            </PusherProvider>
        </Router>
        </>
    );
}

export default AppRouter;


