export const authState = {
    data: {},
    isLoggedIn: null,
};

export const instructorState = {
    chat_students: [],
    chat_messages: {},
    schedule: null,
    requests: null,
    enrollments: [],
    courses: [],
    students: [],
    classes: []
};


export const studentState = {
    chat_instructors: [],
    chat_messages: {} ,
    instructors: [],
    schedule: null,
    requests: null,
    classes: null,
    enrollments: null,
}


export const messageState = {
    newMessage: {
        message: '',
        user: {}
    }
};




export const landingState = {
    instrument: '',
    courses: [],
    instructors: [],
};

export const countryState = {
    country: '',
}