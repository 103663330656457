import { countryState } from "../initialState";

const countryReducer = (state = countryState, action) => {
    switch (action.type) {
        case 'SET_COUNTRY':
            return {
                ...state,
                country: action.payload,
            };
        default:
            return state;
    }
};

export default countryReducer;
