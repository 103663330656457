import React from 'react';
import combineReducers from 'react-combine-reducers';
import authReducer from './reducers/auth'
import studentReducer from './reducers/student'
import instructorReducer from './reducers/instructor'
import messageReducer from './reducers/messages'
import landingReducer from './reducers/landing'
import {authState, studentState, instructorState, messageState, landingState, countryState} from './initialState'
import countryReducer from './reducers/conutry';



const [rootReducerCombined, initialStateCombined] = combineReducers({ 
    user: [authReducer, authState], 
    student: [studentReducer, studentState], 
    instructor: [instructorReducer, instructorState], 
    message: [messageReducer, messageState], 
    landing: [landingReducer, landingState],
    country: [countryReducer, countryState]
})


export const Store = React.createContext("");

export const StoreProvider = (props) => {

    const [state, dispatch] = React.useReducer(rootReducerCombined, initialStateCombined);
    const value = { state, dispatch };
    return <Store.Provider value={value}>{props.children}</Store.Provider>;
    
}





